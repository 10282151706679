const state = () => ({
    phone: '',
    password: '',
});

const mutations = {
    onChange(state, { name, value }) {
        state[name] = value;
    },
    resetAuth(state) {
        state.phone = ''
        state.password = ''
    }
};

const actions = {}

export default {
    state,
    mutations,
    actions,
};