const state = () => ({
  active: false,
})

const getters = {
  hasBranches: (state, getters, rootState) => {
    const branches = rootState.app.settings?.branches
    return Array.isArray(branches) && branches.length
  },

  fieldEnabled: (state, getters, rootState) => (field) => {
    const fields = {
      housing: 'housing',
      apart: 'flat',
      floor: 'et',
      pod: 'pod',
      domofon: 'domofon',
    }

    return rootState.app.settings?.fields_addons?.[fields[field]] === '2'
  },

  useDeliveryZones: (state, getters, rootState, rootGetters) =>
    rootGetters['app/useGeoAddressDelivery'],

  useStreetAutocomplete: (state, getters, rootState, rootGetters) =>
    rootGetters['app/useStreetAutocomplete'],
}

const mutations = {
  SET_ACTIVE(state, value) {
    state.active = value
  },
}

export default {
  state,
  getters,
  mutations,
}
