<template>
  <div v-if="mapSettings.apiKey">
    <YandexMap
      class="map"
      :class="[mapLoading && 'map-loading']"
      :settings="mapSettings"
      v-bind="mapAttributes"
      @map-was-initialized="onMapReady"
      @click="onMapClick"
    >
      <YmapMarker v-if="marker.coords" v-bind="marker" />
    </YandexMap>

    <div v-if="mapLoading" class="spinner-wrapper">
      <v-progress-circular
        :width="7"
        :size="60"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <div class="mt-4 px-4">
      <h2>Адрес доставки</h2>

      <div class="suggest-input-wrapper">
        <v-text-field
          v-model="address"
          id="address-input"
          class="mb-4"
          label="Адрес"
          outlined
          hide-details
          clearable
          autocomplete="off"
          @click:clear="onClear"
          @input="onSearch"
        />
        <div
          v-if="suggests && suggests?.length && address"
          class="suggests-list"
        >
          <div
            v-for="(item, i) in suggests"
            :key="i"
            class="suggest-item"
            @click="onAddressSelect(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <div class="private-house">
        <v-checkbox v-model="privateHouse" label="Частный дом" dense />
      </div>

      <div v-if="!privateHouse" class="fields">
        <v-text-field
          v-if="additionalFields && additionalFields.pod == 2"
          :value="fields.pod"
          label="Подъезд"
          outlined
          hide-details
          clearable
          type="number"
          @input="updateField('pod', $event)"
        />

        <v-text-field
          v-if="additionalFields && additionalFields.domofon == 2"
          :value="fields.domofon"
          label="Домофон"
          outlined
          hide-details
          clearable
          type="number"
          @input="updateField('domofon', $event)"
        />

        <v-text-field
          v-if="additionalFields && additionalFields.et == 2"
          :value="fields.et"
          label="Этаж"
          outlined
          hide-details
          clearable
          type="number"
          @input="updateField('et', $event)"
        />

        <v-text-field
          v-if="additionalFields && additionalFields.flat == 2"
          :value="fields.apart"
          label="Квартира"
          outlined
          hide-details
          clearable
          type="number"
          @input="updateField('apart', $event)"
        />
      </div>
    </div>
  </div>

  <div v-else class="alert">
    <v-alert text type="error" class="text-body-1">
      <span class="font-weight-bold">Доставка по адресу недоступна.</span>
      <br />
      У ресторана отсутствуют необходимые настройки. Пожалуйста, сообщите о
      проблеме по телефону
      <span class="font-weight-bold">{{ phone || phone2 }}</span> или выберите
      другой тип заказа.
    </v-alert>
  </div>
</template>

<script>
import {
  yandexMap as YandexMap,
  ymapMarker as YmapMarker,
} from 'vue-yandex-maps'
import fetchJsonp from 'fetch-jsonp'
import deliveryMapMixin from '@/mixins/deliveryMapMixin'

export default {
  map: null,

  emits: ['setLoading'],

  components: {
    YandexMap,
    YmapMarker,
  },

  mixins: [deliveryMapMixin],

  data() {
    return {
      address: '',
      marker: {
        markerId: '1',
        coords: null,
        hintContent: '',
      },
      cityBounds: null,
      suggests: [],
      disabledZones: [],
      mapLoading: false,
      cityCoordinates: null,
    }
  },

  computed: {
    mapSettings() {
      return {
        apiKey: this.$store.state.app.settings.merchant_yandex_api_key,
      }
    },
  },

  watch: {
    address(value) {
      if (!value?.length) {
        this.suggests = []
      }
    },

    addressActive(value) {
      if (value) {
        this.address = this.shortAddress()
        if (this.initialAddress) {
          this.geoCode(this.initialAddress)
        } else {
          this.address = this.city + ', '
        }
      }
    },
  },

  mounted() {
    if (this.initialAddress) {
      this.geoCode(this.initialAddress)
    } else {
      this.address = this.city + ', '
    }
    document.addEventListener('click', this.onDocumentClick)
  },

  destroyed() {
    document.removeEventListener('click', this.onDocumentClick)
  },

  methods: {
    onAddressSelect(address) {
      this.geoCode(address)
      this.suggests = []
    },

    async querySuggests() {
      let url =
        'https://suggest-maps.yandex.ru/suggest-geo?' +
        'bases=geo,biz&origin=jsapi2Geocoder&fullpath=1&lang=ru_RU&pos=10&v=9' +
        '&part=' +
        encodeURIComponent(this.address)

      if (this.cityCoordinates) {
        url += '&ll=' + this.cityCoordinates.join(',')
        url += '&spn=2,2'
      }

      const response = await fetchJsonp(url).then((r) => r.json())

      this.suggests = response.results
        ?.filter((item) => {
          return (
            item.tags &&
            (item.tags.includes('street') || item.tags.includes('house')) &&
            !item.text.includes(', подъезд')
          )
        })
        .map((item) => item.text.trim())
    },

    onSearch() {
      if (!this.address) return

      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.querySuggests()
      }, 750)
    },

    async onMapReady(mapInstance) {
      this.map = mapInstance

      await this.onCitySelect(this.city)
      if (this.$store.getters['app/useDeliveryZones']) {
        await this.generateZones()
      }
    },

    onCitySelect(city) {
      return window.ymaps.geocode(city, { results: 1 }).then(async (res) => {
        const firstGeoObject = res.geoObjects.get(0)
        this.map.setBounds(firstGeoObject.properties.get('boundedBy'))
        this.cityCoordinates = firstGeoObject?.geometry
          ?.getCoordinates()
          ?.reverse()
        await this.$nextTick()
      })
    },

    geoCode(query) {
      window.ymaps
        .geocode(query, { results: 1, boundedBy: this.map.getBounds() })
        .then(async (res) => {
          if (this.isPointInDisabled(res.geoObjects.get(0))) {
            this.$store.commit('snackbar/update', {
              active: true,
              text: 'Доставка по данному адресу временно недоступна',
            })
            this.onClear()
            return
          }
          const geoObject = res.geoObjects.get(0)
          const addressName = geoObject.getAddressLine()
          const coords = geoObject.geometry.getCoordinates()

          this.$set(this.marker, 'hintContent', addressName)

          if (!Array.isArray(query)) {
            this.$set(this.marker, 'coords', coords)
            this.map.setBounds(geoObject.properties.get('boundedBy'))
          }
          this.updateField('addressLine', addressName)

          this.updateField('addressLine', geoObject.getAddressLine())
          this.updateField('addressCoords', coords)

          const localities = geoObject.getLocalities()
          const house =
            geoObject?.getPremiseNumber() ||
            geoObject.properties.get('name').split(',')[1]

          const lastLocality = localities?.[localities.length - 1]
          const street = geoObject.getThoroughfare()
            ? geoObject.getThoroughfare()
            : house && lastLocality !== this.city
            ? lastLocality
            : geoObject?.properties.get('name').split(',')[0]

          this.updateField('street', street)
          this.updateField('house', house)
          this.updateField('addressCity', localities?.[0])

          this.address = this.shortAddress()
          this.mapLoading = false
        })
    },

    onDocumentClick(e) {
      const id = e.target.id
      if (id !== 'address-input') {
        this.suggests = []
      }
    },
  },
}
</script>

<style lang="scss">
.map {
  height: 50vh;
}

.map-loading {
  opacity: 0;
}

.spinner-wrapper {
  position: absolute;
  top: 0;
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.private-house {
  max-width: 40%;
}

.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

h2 {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 10px;
}

.alert {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 16px;
}
</style>
