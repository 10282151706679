import {
  Observable,
  Subject,
  BehaviorSubject,
  ReplaySubject,
  AsyncSubject,
  Subscription,
} from 'rxjs'

import Vue from 'vue'

import VueRx from 'vue-rx'

Vue.use(VueRx, {
  Subscription,
  BehaviorSubject,
  ReplaySubject,
  AsyncSubject,
  Observable,
  Subject,
})
