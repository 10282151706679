<template>
  <v-container class="text-center mt-n10">
    <v-img v-if="logo" :src="logo" height="80" class="mx-auto mb-4" contain />

    <div class="app-name dark--text mb-6">{{ $store.getters['app/name'] }}</div>
    <v-select
      v-model="location"
      :items="locations"
      class="location-select"
      outlined
      @change="changeCity"
    >
      <template v-slot:prepend-inner>
        <v-img src="@/assets/images/marker.svg" class="ml-1 mr-2" />
      </template>
      <template v-slot:append>
        <v-icon color="secondary">{{ icons.mdiDotsVertical }}</v-icon>
      </template>
    </v-select>

    <PhoneButton v-if="phone" :phone="phone" />
    <br />
    <PhoneButton v-if="phone2" :phone="phone2" />

    <ul class="menu-list dark--text">
      <li v-for="(item, i) in menuList" :key="i">
        <v-btn
          class="menu-list-link"
          text
          block
          large
          :to="item.to"
          exact
          @click="item.to ? $store.dispatch('menu/hide') : ''"
        >
          {{ item.text }}
        </v-btn>
      </li>
    </ul>

    <SocialLinks />

    <a v-if="isWhiteLabel"
      :href="`https://vsem-edu.ru/cloud?utm_source=referal&utm_campaign=${merchant}`"
      target="_blank"
      class="external-link dev-link"
      >Работает на платформе Всем еды</a
    >

    <div
      v-if="$store.getters['app/recaptchaEnabled']"
      class="pa-3"
      :style="{ fontSize: '14px' }"
    >
      <p class="mb-1">Этот сайт защищен reCAPTCHA и Google</p>
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        class="external-link"
      >
        Политика конфиденциальности
      </a>
      и
      <a
        href="https://policies.google.com/terms"
        target="_blank"
        class="external-link"
      >
        Условия использования
      </a>
    </div>
  </v-container>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import { mapState } from 'vuex'
import PhoneButton from '@/components/PhoneButton.vue'
import SocialLinks from '@/components/SocialLinks.vue'

export default {
  data: () => ({
    icons: {
      mdiDotsVertical,
    },
    location: '',
  }),

  components: {
    PhoneButton,
    SocialLinks,
  },

  computed: {
    locations() {
      const values = []

      const city = this.$store.state.app?.settings?.city

      if (city) {
        values.push({ text: city, value: null })
      }

      const cities = this.cities
      if (cities) {
        Object.values(cities).forEach((item) => {
          values.push({
            text: item.city,
            value: item.merchant_key,
          })
        })
      }

      const branches = this.branches

      if (Array.isArray(branches)) {
        branches.forEach((item) => {
          if (!item) return
          values.push({
            text: item,
            value: item,
          })
        })
      }

      return values
    },

    branches() {
      return this.$store.state.app?.settings?.branches_open || []
    },

    cities() {
      return this.$store.getters['app/cities']
    },

    phone() {
      return this.$store.getters['app/phone']
    },

    phone2() {
      return this.$store.getters['app/phone2']
    },

    logo() {
      return this.$store.getters['app/logo']
    },

    menuList() {
      const list = [
        {
          text: 'Главная',
          to: { name: 'Home' },
        },
        /* {
          text: "Экран входа",
          to: { name: "Welcome" }
        } */
      ]
      if (this.pages) {
        this.pages.forEach((item) => {
          list.push({
            text: item.page_name,
            to: { name: 'CustomPage', params: { id: item.id } },
          })
        })
      }
      return list
    },

    isWhiteLabel() {
      return this.$store.getters['app/isWhiteLabel']
    },

    merchant() {
      return this.$store.getters['app/name']
    },

    ...mapState('menu', { menu: 'active' }),
    ...mapState('app', ['pages']),
  },

  methods: {
    changeCity(v) {
      if (this.branches.includes(v)) {
        this.$store.commit('snackbar/update', {
          active: true,
          text: 'Приложение для этого населенного пункта находится в разработке',
        })
        return
      }

      const key = this.location
      if (key) {
        localStorage.setItem('merchantKey', key)
        if (this.$route.name != 'Home') {
          this.$router.push({ name: 'Home' })
        }
        window.location.href = window.location.origin
      }
    },
  },

  created() {
    if (this.locations?.length) {
      this.location = this.locations[0]
    }
  },
}
</script>

<style lang="scss" scoped>
.menu-list {
  list-style: none;
  margin: 0;
  padding: 40px 0;
}

.menu-list-link {
  height: auto !important;
  min-height: 44px;
  ::v-deep .v-btn__content {
    flex-shrink: 1;
    white-space: normal;
  }
}

@media (max-width: 385px) {
  .menu-list-link {
    ::v-deep .v-btn__content {
      max-width: 298px;
    }
  }
}

.app-name {
  font-size: 21px;
  font-weight: 900;
}

.dev-link {
  color: #b8b8b8;
  text-decoration: none;
  font-size: 12px;
}
</style>
