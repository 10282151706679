const state = () => ({
  active: false,
  fetching: false,
})

const mutations = {
  update(state, params) {
    for (const key in params) {
      const value = state.fetching ? true : params[key]
      this._vm.$set(state, key, value)
    }
  },

  setFetching(state, value) {
    state.fetching = value
  }
}

const actions = {}

export default {
  state,
  mutations,
  actions,
}
