<template>
  <div class="order-type-buttons">
    <button
      v-for="(orderType, i) in orderTypes"
      v-ripple
      :key="i"
      :class="{ active: selectedOrderType === orderType.value }"
      @click.prevent="selectOrderType(orderType)"
    >
      {{ orderType.text }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('checkout', ['orderTypes', 'selectedOrderType']),
  },

  methods: {
    ...mapMutations('address', ['SET_ACTIVE']),
    ...mapMutations('checkout', ['setOrderType']),

    selectOrderType(orderType) {
      this.setOrderType(orderType.value)
      this.SET_ACTIVE(true)
    },
  },
}
</script>

<style lang="scss">
.order-type-buttons {
  display: flex;
  border-radius: 16px;
  background: #eee;
  overflow: hidden;

  .theme--dark & {
    background: #333333;
  }

  button {
    flex: 1;
    height: 44px;
    border-radius: 16px;
    padding: 8px;
    background: transparent;
    transition: 0.15s ease-out;
    line-height: 1;

    &.active {
      background: var(--v-primary-base);
      color: #fff;
    }
  }
}
</style>
