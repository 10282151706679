import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import Emitter from 'tiny-emitter'
import { v4 as uuidv4 } from 'uuid'
import store from '@/store/index'

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  install(Vue) {
    Vue.prototype.$emitter = new Emitter()

    const isDev = window.location.protocol === 'http:'

    const track = (type) => {
      if (isDev) return
      const dateTime = dayjs().format('DD.MM.YYYY, HH:mm:ss')

      const merchantId = store.state.app.settings.theme_option.merchant_id

      let sessionId = localStorage.getItem('custom-tracker-session-id')
      if (!sessionId) {
        sessionId = uuidv4()
        localStorage.setItem('custom-tracker-session-id', sessionId)
      }

      const data = {
        source: {
          id: '951b248f-07e7-4831-aaec-849efa961c90',
        },
        context: {
          time: {
            local: dateTime,
            tz: dayjs.tz.guess(),
          },
        },
        profile: {
          id: '',
        },
        session: {
          id: sessionId,
        },
        options: {},
        events: [
          {
            properties: {
              merchantId,
              frontType: 'mobile_app',
            },
            type,
            context: {
              page: {
                url: window.location.href,
                path: window.location.pathname,
                hash: '',
                title: document.title,
                referer: {
                  host: null,
                  query: null,
                },
                history: {
                  length: window.history.length,
                },
              },
            },
          },
        ],
      }

      axios.post('https://analytics.vsem-edu-oblako.ru:8686/track', data)
    }

    Vue.prototype.$emitter.on('mobile-app-launch', () => {
      track('mobile-app-launch')
    })

    Vue.prototype.$emitter.on('menu-view', () => {
      track('menu-view')
    })

    Vue.prototype.$emitter.on('product-add', () => {
      track('product-add')
    })
  },
}
