const routes = [
  {
    path: "/personal",
    name: "Personal",
    component: () => import("@/views/Personal/Personal.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/personal/bonuses",
    name: "Bonuses",
    component: () => import("@/views/Personal/Bonuses/Bonuses.vue"),
    meta: {
      auth: true,
      backButton: true,
    },
  },
  {
    path: "/personal/bonuses/up",
    name: "BonusesUp",
    component: () => import("@/views/Personal/Bonuses/Up.vue"),
    meta: {
      auth: true,
      backButton: true,
    },
  },
  {
    path: "/personal/bonuses/down",
    name: "BonusesDown",
    component: () => import("@/views/Personal/Bonuses/Down.vue"),
    meta: {
      auth: true,
      backButton: true,
    },
  },
  {
    path: "/personal/history",
    name: "History",
    component: () => import("@/views/Personal/History/History.vue"),
    meta: {
      auth: true,
      backButton: true,
    },
  },
  {
    path: "/personal/history/:id",
    name: "HistoryDetail",
    component: () => import("@/views/Personal/History/Detail.vue"),
    meta: {
      auth: true,
      backButton: true,
    },
  },
  {
    path: "/personal/settings",
    name: "UserSettings",
    component: () => import("@/views/Personal/Settings/Settings.vue"),
    meta: {
      auth: true,
      backButton: true,
    },
  },
  {
    path: "/personal/settings/profile",
    name: "UserSettingsProfile",
    component: () => import("@/views/Personal/Settings/Profile.vue"),
    meta: {
      auth: true,
      backButton: true,
    },
  },
  {
    path: "/personal/settings/password",
    name: "UserSettingsPassword",
    component: () => import("@/views/Personal/Settings/Password.vue"),
    meta: {
      auth: true,
      backButton: true,
    },
  },
  {
    path: "/personal/addresses",
    name: "Addresses",
    component: () => import("@/views/Personal/Addresses/Addresses.vue"),
    meta: {
      auth: true,
      backButton: true,
    },
  },
  {
    path: "/personal/addresses/:id",
    name: "AddressDetail",
    component: () => import("@/views/Personal/Addresses/Detail.vue"),
    meta: {
      auth: true,
      backButton: true,
    },
  },
];

export default routes;
