const state = () => ({
  key: null,
  settings: null,
})

const getters = {
  isAggregator(state, getters, rootState) {
    return !!rootState.app.settings?.merchant_aggregator
  },
  isAggregatorChild(state) {
    return !!state.settings
  },
  merchantList(state, getters, rootState) {
    return rootState.app.settings.aggregator_share
  },
  key(state) {
    return state.key
  },
}

const mutations = {
  setKey(state, value) {
    state.key = value
  },
  setSettings(state, value) {
    state.settings = value
  },
}

const actions = {
  async loadSettings({ commit }) {
    const aggregatorKey = sessionStorage.getItem('aggregatorKey')

    if (
      aggregatorKey &&
      aggregatorKey !== localStorage.getItem('merchantKey')
    ) {
      const response = await this.$api.get('getAppSettings', {
        params: { merchant_keys: aggregatorKey },
      })

      const isAggregator = !!response?.details?.merchant_aggregator

      if (isAggregator) {
        commit('setSettings', response.details)
      }
    }
  },

  backToHome() {
    localStorage.setItem('merchantKey', sessionStorage.getItem('aggregatorKey'))
    window.location.reload()
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
