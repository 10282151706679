/**
 * Element Utils class.
 * Provide some operations above HTMLDomElements hierarchy.
 *
 * @export
 * @class ElementUtils
 */
export class ElementUtils {
  /**
   * Provide static m ethod for calculations
   * internal scripts elements by
   *
   * @static
   * @param  {any} [attrName=null]
   * @return {void}@memberof ElementUtils
   */
  static hasElementByAttr(
    elementAttr = 'div',
    attrName = null,
    attrValue = null
  ) {
    try {
      const scriptElements = []
      if (!attrName || !attrName) {
        throw new TypeError(
          `Method ${this.name} was not provided by empty or null 'attrName' and 'attrValue' values`
        )
      }

      const scriptElementRefs = document.getElementsByTagName(elementAttr)
      if (!scriptElementRefs.length) {
        throw new RangeError(
          `Method ${this.name} was provided by empty 'elementAttr' in the scheme`
        )
      }

      let scriptElementIdx = 0
      do {
        const scriptElementRef =
          scriptElementRefs && scriptElementRefs[scriptElementIdx]
        /** @internal */
        scriptElements.push(scriptElementRef)
        scriptElementIdx++
      } while (scriptElementIdx < scriptElementRefs.length)

      return scriptElements.some(
        (scriptElement) =>
          scriptElement.hasAttribute(attrName) &&
          scriptElement.getAttribute(attrName)?.includes(attrValue)
      )
    } catch (e) {
      // eslint-disable-next-line
      console.warn(e.message)
      return null
    }
  }
}
