const state = () => ({
  slides: []
});

const mutations = {
  setSlides(state, slides) {
    state.slides = slides;
  }
};

const actions = {
  async loadSlides({ commit }) {
    const response = await this.$api.get("loadSliders");
    const slides =
      (response && response.details && response.details.data) || [];
    commit("setSlides", slides);
  }
};

export default {
  state,
  mutations,
  actions
};
