const state = () => ({
  /* active: null,
  loading: null,
  text: null,
  action: null, */
});

const mutations = {
  update(state, button) {
    for (const key in button) {
      const value = button[key];
      // state[key] = value;
      this._vm.$set(state, key, value);
    }
  },

  clear(state) {
    for (const key in state) {
      // state[key] = null;
      this._vm.$set(state, key, null);
    }
  },
};

export default {
  state,
  mutations,
};
