import Vue from "vue";
import Vuetify from "vuetify/lib";
import ru from "vuetify/es5/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: "#90D270",
        secondary: "#B8B8B8",
        dark: "#333333",
        white: "#ffffff",
        error: "#F08541"
      },
      dark: {
        primary: "#90D270",
        secondary: "#B8B8B8",
        dark: "#ffffff",
        white: "#333333",
        error: "#F08541"
      }
    }
  },
  lang: {
    locales: { ru },
    current: "ru"
  }
});
