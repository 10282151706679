/* eslint-disable no-undef */
const LINK_STRATEGY = 'linkStrategy'
const SCRIPT_STRATEGY = 'scriptStrategy'
import { mapGetters } from 'vuex'
import { ElementUtils } from '../utils/element-utils'
// import { ElementUtils } from '../utils/element-utils'

export default {
  data: () => ({
    isLoaded: false,
    mainRoutes: [
      'Cart',
      'Gift',
      'Checkout',
      'PaymentSuccess',
      'Login',
      'Registration',
      'ForgotPass',
      'Sales',
      'Welcome',
    ],
  }),
  computed: {
    ...mapGetters({ userPhone: ['user/phone'] }),
    isAuthorized() {
      return this.$store.getters['user/isAuthorized']
    },
    appShop() {
      return this.$store.getters['app/appShop']
    },
    wheelConfig() {
      return this.$store.getters['wheel/wheelConfig']
    },
    defaultConfigURI() {
      const { script, apiRoot } = this.wheelConfig
      const ɵroot = [apiRoot, script].join('/')
      return (mimeType) => {
        return [ɵroot, mimeType].join('.')
      }
    },
  },
  methods: {
    linkFactory(root, name, mimeType = 'css', rel = 'stylesheet') {
      const linkElement = document.createElement('link')
      const ɵroot = [root, name].join('/')
      linkElement.href = `${ɵroot}.${mimeType}`
      linkElement.rel = rel
      return linkElement
    },
    scriptFactory(
      root,
      name,
      mimeType = 'js',
      type = 'text/javascript',
      async = true
    ) {
      const scriptElement = document.createElement('script')
      const ɵroot = [root, name].join('/')
      scriptElement.src = `${ɵroot}.${mimeType}`
      scriptElement.type = type
      scriptElement.async = async
      scriptElement.defer = async
      return scriptElement
    },

    asyncBootstrap({ strategy = LINK_STRATEGY }) {
      const { link, script, apiRoot } = this.wheelConfig
      const { storeHash } = this.appShop
      return new Promise((resolve, reject) => {
        const element = strategy.includes(LINK_STRATEGY)
          ? this.linkFactory(apiRoot, link)
          : this.scriptFactory(apiRoot, script)
        document[
          element instanceof HTMLLinkElement ? 'head' : 'body'
        ].appendChild(element)
        try {
          element.addEventListener(
            'load',
            (e) => {
              resolve(true)

              if (strategy.includes(SCRIPT_STRATEGY)) {
                try {
                  if (!WidgetWheel) return reject(WidgetWheel)
                  if (!storeHash) return reject(e)
                  void new WidgetWheel(storeHash, this.userPhone)
                } catch (e) {
                  return reject(e)
                }
              }
            },
            false
          )
          element.addEventListener('error', (e) => reject(e), false)
        } catch (e) {
          reject(e)
        }
      })
    },
  },
  async updated() {
    if (!this.mainRoutes.includes(this.$route.name)) {
      const { storeHash, availability } = this.appShop || {}
      const isHTMLScriptAndLinkExists =
        ElementUtils.hasElementByAttr(
          'script',
          'src',
          this.defaultConfigURI('js')
        ) ||
        ElementUtils.hasElementByAttr(
          'link',
          'href',
          this.defaultConfigURI('css')
        )
      try {
        if (!availability || !storeHash) return null
        if (isHTMLScriptAndLinkExists) return null

        await Promise.allSettled([
          this.asyncBootstrap({ strategy: LINK_STRATEGY }),
          this.asyncBootstrap({ strategy: SCRIPT_STRATEGY }),
        ]).then((bootstraps) =>
          bootstraps
            .filter((bootstrap) => bootstrap.status === 'fulfilled')
            .map((bootstrap) => bootstrap.value)
        )
      } finally {
        this.$nextTick(() => (this.isLoaded = true))
      }
    }
  },
  async mounted() {
    const { storeHash, availability } = this.appShop || {}
    try {
      if (!availability || !storeHash) return null
      if (this.isHTMLScriptAndLinkExists) return null
      await Promise.allSettled([
        this.asyncBootstrap({ strategy: LINK_STRATEGY }),
        this.asyncBootstrap({ strategy: SCRIPT_STRATEGY }),
      ]).then((bootstraps) =>
        bootstraps
          .filter((bootstrap) => bootstrap.status === 'fulfilled')
          .map((bootstrap) => bootstrap.value)
      )
    } finally {
      this.$nextTick(() => (this.isLoaded = true))
    }
  },
}
