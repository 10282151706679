<template>
  <v-snackbar :value="snackbar.active" @input="onInput" :timeout="3000" top>
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn color="error" text v-bind="attrs" @click="clear">Закрыть</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["snackbar"]),
  },

  methods: {
    ...mapMutations("snackbar", ["update", "clear"]),

    onInput(value) {
      this.update({ active: value });
    },
  },
};
</script>