const state = () => ({
    name: '',
    phone: '',
    password: '',
    passwordRepeat: '',
});

const mutations = {
    onChange(state, { name, value }) {
        state[name] = value;
    },
    resetRegistration(state) {
        state.name = ''
        state.phone = ''
        state.password = ''
        state.passwordRepeat = ''
    }
};

const actions = {}

export default {
    state,
    mutations,
    actions,
};