/**
 * @export
 * @enum EShopSettings
 */
export const EShopSettings = {
  SERVED: "SERVED",
  CLOSED: "CLOSED",
  ORDERED: "ORDERED",
  FEASTED: "HOLIDAY",
  DEFAULT: "DEFAULT",
};
