const CACHE_LIFETIME = 60 * 60 * 1000

const state = () => ({
  sections: [],
  products: {},
  details: {},
  productsRequestDate: null,
})

const getters = {
  getProductDetailsByID: (state) => (id) => state.details[id],
}

const mutations = {
  setSections(state, sections) {
    state.sections = sections
  },
  setProducts(state, { sectionID, products }) {
    this._vm.$set(state.products, sectionID, products)
  },
  setProductDetails(state, { itemID, item }) {
    this._vm.$set(state.details, itemID, item)
  },
  clearProducts(state) {
    state.products = {}
  },
  setProductsRequestDate(state, value) {
    state.productsRequestDate = value
  },
}

const actions = {
  async loadSections({ commit }) {
    const response = await this.$api.get('loadCategory')
    const sections =
      (response && response.details && response.details.data) || []
    commit('setSections', sections)
  },

  async loadProducts({ state, commit }, sectionID) {
    if (
      state.productsRequestDate &&
      new Date() - state.productsRequestDate > CACHE_LIFETIME
    ) {
      commit('clearProducts')
      commit('setProductsRequestDate', new Date())
    }

    if (!state.productsRequestDate) {
      commit('setProductsRequestDate', new Date())
    }

    if (state.products[sectionID]) return
    commit('overlay/update', { active: true }, { root: true })
    const response = await this.$api.get('loadItemByCategory', {
      params: { cat_id: sectionID },
    })
    const products =
      (response && response.details && response.details.data) || []
    commit('setProducts', { sectionID, products })
    commit('overlay/update', { active: false }, { root: true })
  },

  async loadProductDetails({ commit }, { id, row }) {
    // if (state.details[id] && row === undefined) return;
    commit('overlay/update', { active: true }, { root: true })
    const response = await this.$api.get('loadItemDetails', {
      params: { item_id: id, row },
    })
    const details = response && response.details
    const product = details && details.data
    const cartData = details && details.cart_data
    if (cartData) product['cart_data'] = cartData
    commit('setProductDetails', { itemID: id, item: product })
    commit('overlay/update', { active: false }, { root: true })
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
