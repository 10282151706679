const state = () => ({
  config: {
    apiRoot: 'https://wheel.vsem-edu-oblako.ru',
    link: 'widjet',
    script: 'widjet',
  },
})

const getters = {
  wheelConfig: (s) => s.config,
}

export default {
  state,
  getters,
}
