<template>
  <div v-if="isBottomPresentPosition && display && scrollDisplay">
    <div
      class="present present-base-mobile"
      :class="freeDelivery <= animatedNumber ? 'transparency' : ''"
    >
      <div
        class="present-wrap"
        :class="
          minPrice == cartPrice
            ? 'present-black'
            : listPresent[0].presents_summ <= cartPrice
            ? 'present-ligth'
            : 'present-black'
        "
      >
        <div
          class="present-icon opacity-0"
          :style="
            minPrice <= animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <div class="present-icon__wrap">
            <img src="@/assets/images/gift/begin_white.svg" alt="begin_white" />
          </div>
        </div>
        <div
          class="present-icon opacity-0"
          :style="
            freeDelivery < animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <div class="present-icon__wrap">
            <img
              src="@/assets/images/gift/delivery_white.svg"
              alt="delivery_white"
            />
            <img
              class="present-icon__wrap-true"
              src="@/assets/images/gift/true.svg"
              alt="true"
              style="width: 8px"
            />
          </div>
        </div>
        <div class="present-txt"></div>
        <div
          class="present-icon"
          :style="
            minPrice >= animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <img
            class="opacity-0"
            src="@/assets/images/gift/begin_white.svg"
            alt="begin_white"
          />
        </div>
        <div
          class="present-icon"
          :style="
            freeDelivery >= animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <img
            class="opacity-0"
            src="@/assets/images/gift/begin_white.svg"
            alt="begin_white"
          />
        </div>
      </div>
    </div>
    <div class="present transparency present-base-mobile">
      <div class="present-wrap transparency">
        <div
          class="present-icon opacity-0"
          :style="
            minPrice <= animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <div class="present-icon__wrap">
            <img src="@/assets/images/gift/begin_white.svg" alt="begin_white" />
          </div>
        </div>
        <div
          class="present-icon opacity-0"
          :style="
            freeDelivery < animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <div class="present-icon__wrap">
            <img
              src="@/assets/images/gift/delivery_white.svg"
              alt="delivery_white"
            />
            <img
              class="present-icon__wrap-true"
              src="@/assets/images/gift/true.svg"
              alt="true"
              style="width: 8px"
            />
          </div>
        </div>
        <div class="present-txt transparency">
          <span class="present-bg" :style="styleMinPrice">
            <div class="present-icon__bg"></div>
          </span>
        </div>
        <div
          class="present-icon transparency"
          :style="
            minPrice >= animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <img
            class="opacity-0"
            src="@/assets/images/gift/begin_white.svg"
            alt="begin_white"
          />
        </div>
        <div
          class="present-icon transparency"
          :style="
            freeDelivery >= animatedNumber
              ? { display: 'block' }
              : { display: 'none' }
          "
        >
          <img
            class="opacity-0"
            src="@/assets/images/gift/begin_white.svg"
            alt="begin_white"
          />
        </div>
      </div>
    </div>
    <div class="present transparency present-base-mobile">
      <div class="present-wrap transparency">
        <div
          class="present-icon"
          :style="
            minPrice <= animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <div class="present-icon__wrap">
            <img src="@/assets/images/gift/begin_white.svg" alt="begin_white" />
            <img
              class="present-icon__wrap-true"
              src="@/assets/images/gift/true.svg"
              alt="true"
              style="width: 8px"
            />
          </div>
        </div>
        <div
          class="present-icon"
          :style="
            freeDelivery < animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <div class="present-icon__wrap">
            <img
              src="@/assets/images/gift/delivery_white.svg"
              alt="delivery_white"
            />
            <img
              class="present-icon__wrap-true"
              src="@/assets/images/gift/true.svg"
              alt="true"
              style="width: 8px"
            />
          </div>
        </div>
        <div class="present-txt transparency" @click="toGift">
          <div class="present-icon__bg transparency">
            <img
              v-if="cartPrice === 0"
              src="@/assets/images/gift/begin_dark_gray.svg"
              alt="begin_dark_gray"
            />
            <img
              v-else-if="minPrice > cartPrice"
              src="@/assets/images/gift/begin_white.svg"
              alt="begin_white"
            />
            <img
              v-else-if="freeDelivery >= cartPrice"
              src="@/assets/images/gift/delivery_white.svg"
              alt="begin_white"
            />
          </div>

          <span
            v-if="freeDelivery >= cartPrice"
            class="present-promo present-promo-mobile"
          >
            <span v-if="minPrice >= cartPrice">
              Минимальная сумма заказа {{ minPrice }}
              {{ $store.getters["app/currency"] }}
            </span>
            <span v-else-if="freeDelivery >= cartPrice">
              Минимальная сумма бесплатной доставки {{ freeDelivery }}
              {{ $store.getters["app/currency"] }}
            </span>
          </span>
          <div v-else class="present-txt-list present-txt-w present-txt-mobile">
            <span
              to="/gift"
              class="
                present-icon__bg present-icon__bg-c
                present-txt-item
                present-icon__bg--color
                relative
                z-50
              "
              @click="toGift"
            >
              <img
                src="@/assets/images/gift/present_white.svg"
                alt="present_white"
              />
            </span>

            <span
              v-for="(present, i) in listPresent"
              :key="i"
              class="present-txt-item"
            >
              <span
                v-if="
                  present.presents_summ <= cartPrice &&
                  listPresent[i + 1] &&
                  cartPrice < listPresent[i + 1].presents_summ
                "
                to="/gift"
                class="
                  border-b border-dotted
                  cursor-pointer
                  flex
                  items-center
                  relative
                  z-50
                "
                @click="toGift"
              >
                выбрать
              </span>
              <span
                v-else-if="
                  listPresent[listPresent.length - 1].presents_summ <=
                    cartPrice &&
                  present.presents_summ ===
                    listPresent[listPresent.length - 1].presents_summ
                "
                to="/gift"
                class="
                  border-b border-dotted
                  cursor-pointer
                  flex
                  items-center
                  relative
                  z-50
                "
                @click="toGift"
              >
                выбрать
              </span>
              <span
                v-else-if="present.presents_summ < cartPrice"
                class="opacity-50 relative z-50"
                >{{ present.presents_summ }}
                {{ $store.getters["app/currency"] }}</span
              >
              <span v-else class="relative z-50"
                >{{ present.presents_summ }}
                {{ $store.getters["app/currency"] }}</span
              >
            </span>
          </div>
        </div>
        <div
          class="present-icon transparency"
          :style="
            minPrice >= animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <img
            src="@/assets/images/gift/delivery_gray.svg"
            alt="delivery_gray"
          />
        </div>
        <div
          class="present-icon transparency"
          :style="
            freeDelivery >= animatedNumber
              ? { display: 'flex' }
              : { display: 'none' }
          "
        >
          <img src="@/assets/images/gift/present_gray.svg" alt="present_gray" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      scrollDisplay: true,
      display: false,
      geoAdressDelivery: {},
    };
  },

  computed: {
    isBottomPresentPosition() {
      return this.$store.getters["app/isBottomPresentPosition"];
    },
    minPrice() {
      return +this.geoAdressDelivery?.params?.min_price || 0;
    },
    freeDelivery() {
      return this.geoAdressDelivery?.params?.free_delivery;
    },
    cartPrice() {
      return +this.cart?.items_total || 0;
    },
    animatedNumber() {
      return this.cartPrice.toFixed(0);
    },
    styleMinPrice() {
      if (this.minPrice > this.cartPrice) {
        return this.calcWidth(this.minPrice);
      }
      if (this.freeDelivery >= this.cartPrice) {
        return this.calcWidth(this.freeDelivery);
      }
      if (this.freeDelivery < this.cartPrice) {
        return this.calcWidth(
          this.listPresent[this.listPresent.length - 1].presents_summ
        );
      }
      return this.calcWidth(this.minPrice);
    },
    styleFreeDelivery() {
      return this.calcWidth(this.freeDelivery);
    },
    stylePrecentMaxPrice() {
      return this.calcWidth(this.precentMaxPrice);
    },
    precentMaxPrice() {
      return +this.listPresent[this.listPresent.length - 1].presents_summ;
    },
    listPresent() {
      const key = "presents_summ";

      return [
        ...new Map(this.data.items.map((item) => [item[key], item])).values(),
      ];
    },
    ...mapState("checkout", ["cart"]),
    ...mapState("gift", ["data"]),
  },

  async mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.onScroll();
    const { dispatch } = this.$store;

    try {
      const response = await dispatch("gift/loadGeoAdressDelivery");

      if (response) {
        this.geoAdressDelivery = response.details;
        this.display = true;
      }
    } catch (err) {
      //   this.$toast.error(err.message);
    }
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    onScroll() {
      let n = document.documentElement.scrollTop + window.innerHeight;

      n = n?.toFixed(0);

      n = Number(n);

      const bottomOfWindow = n >= document.documentElement.offsetHeight;

      if (bottomOfWindow) {
        this.scrollDisplay = false;
      } else {
        this.scrollDisplay = true;
      }
    },

    toGift() {
      this.$router.push("/gift");
    },

    calcWidth(price) {
      const width = (this.cartPrice / price) * 100;

      if (width > 100) {
        return {
          opacity: "1",
          width: `calc(100%)`,
        };
      }

      if (width > 1) {
        return {
          opacity: "1",
          width: `${width}%`,
        };
      }

      return {
        width: `${width}%`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.b-b {
  border-bottom: 1px dotted #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.m-op {
  opacity: 0.5;
}

.rel-in {
  position: relative;
  z-index: 50;
}

.present {
  bottom: 90px;
  left: 10px;
  right: 10px;
  height: 40px;
  display: flex;
  z-index: 3;
  position: fixed;
}

.present-wrap {
  width: 100%;
  border-radius: 20px;
  background: linear-gradient(70deg, #fff, #4e5460);

  display: flex;
  align-items: center;
}

.present-black {
  background: #4e5460;
}

.present-ligth {
  background: linear-gradient(70deg, #4e5460, transparent);
}

.present-icon__wrap-true {
  top: -5px;
  right: -5px;

  position: absolute;
}

.present-txt {
  position: relative;
  flex-grow: 10;
  padding: 12px 8px;
  background-color: #c7cacf;
  border-radius: 20px;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  text-align: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  height: 40px;
}

.present-icon__bg {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.present-promo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
}

.present-icon {
  flex-grow: 0.3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.present-icon__wrap {
  position: relative;
  width: 24px;
  height: 24px;
}

.present-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0;
  border-radius: 20px;
  background-color: #83cd26;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 1s;
}

.present-bg-v3 {
  top: -12px;
  bottom: -12px;
  left: -8px;
  right: -8px;
  cursor: pointer;
}

.present-txt-list {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 100%;
  z-index: 30;
  padding-right: 16px;
}

.present-txt-list .present-icon__bg-c {
  color: #4e5460;
  height: 16px;
  position: relative;
}

.present-icon__bg-c img {
  position: relative;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  color: #fff;
}

.present-promo-mobile {
  white-space: unset;
  margin: 0;
  width: 180px;
  font-size: 10px;
}

.present-txt-mobile {
  flex-grow: 5;
  font-size: 10px;
}

.present-icon-mobile,
.present-mobile {
  flex-grow: 0.6;
}

.present-base-mobile .present-wrap {
  position: relative;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
}

.present-base-mobile .present-icon__wrap {
  width: 24px;
  height: 24px;
}

.present-base-mobile img {
  width: 24px;
}

.present-img-item-mobile {
  width: 12px !important;
}

.true-mobile {
  right: 0;
  width: 14px !important;
}

.transparency {
  background: transparent;
}

.opacity-0 {
  opacity: 0;
}
</style>
